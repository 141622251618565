import { Box, Container, Link as MuiLink } from '@mui/material';
import { useLocation } from 'react-router-dom';

export function getGitHubUrl(from: string) {
  const rootURl = 'https://github.com/login/oauth/authorize';

  const options = {
    client_id: process.env.REACT_APP_GITHUB_OAUTH_CLIENT || '',
    redirect_uri: process.env.REACT_APP_API_URL + '/v1/auth',
    scope: 'repo,user:email',
    state: from,
  }

  const qs = new URLSearchParams(options);

  return `${rootURl}?${qs.toString()}`;
}

const LoginPage = () => {
  const location = useLocation();
  let from = ((location.state as any)?.from?.pathname as string) || '/';

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#e5e7eb',
      }}
    >
      <Box width='27rem'>
        <Box
          width='100%'
          sx={{
            backgroundColor: '#e5e7eb',
            p: { xs: '1rem', sm: '2rem' },
            borderRadius: 2,
          }}
        >
         <MuiLink
            href={getGitHubUrl(from)}
            sx={{
              backgroundColor: '#f5f6f7',
              borderRadius: 1,
              py: '0.6rem',
              mt: '1.5rem',
              columnGap: '1rem',
              textDecoration: 'none',
              color: '#393e45',
              cursor: 'pointer',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: '#fff',
                boxShadow: '0 1px 13px 0 rgb(0 0 0 / 15%)',
              },
            }}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            GitHub
          </MuiLink>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
