import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import LoginPage from './login';
import App from './App';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/deployments/:token",
    element: <App />,
  },
]);

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#ff4539",
    },
    background: {
      default: "#0a0325",
      paper: '#0a0033',
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline enableColorScheme/>
    <CookiesProvider>
      <RouterProvider router={router} />
    </CookiesProvider>
  </ThemeProvider>
);
